.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
}

.pagination .page-item, .pagination :where(.next-item, .prev-item){
  margin: 0px .5em;
  border-radius: 0.25rem;
  padding: .2rem 0rem;
  color: var(--C_gray);
  cursor: pointer;
  border: .5px solid var(--C_gray);
  transition: all 0.2s ease-in-out;  
}
.pagination .page-item .page-link{
 padding: 0.5rem .7rem;
}

.pagination :where(.next-item, .prev-item, .active){
 background-color: var(--C_danger) !important;
 color: var(--C_white) !important;
 border-color: var(--C_danger) !important;
}
.pagination :where(.next-item, .prev-item){
 padding: 0.25rem 0.3rem !important;
 border-color: var(--C_danger) !important;
}
.pagination .active a{
 cursor: default !important;
}

.pagination .page-item:hover {
  background-color: var(--C_danger_hover);
  color: var(--C_white);
}

.pagination .disabled, .pagination .disabled a {
 background-color: var(--C_danger_disabled) !important;
  cursor: default;
  border-color: var(--C_danger_disabled) !important;
}