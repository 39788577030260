.modal__wrapper_two .modal__two {
 /* min-width: 400px;
 position: fixed;
 right: 3em;
 top: 11em;
 height: 100%;
 background-color: #EFF3F7;
 display: block;
 border-radius: 20px;
 padding: 10px 20px; */
 min-width: 100%;
 height: 100%;
 background-color: #FFFFFF;
 display: block;
 border-radius: 5px;
 padding: 10px 20px;
}

.modal__wrapper_two {
 margin: 5vh 30vw;
 min-width: 600px;
 height: 400px;
 display: block;
 position: absolute;
 /* top: 20px; */
 z-index: 300;
 
}

.modal__two .select_role::placeholder {
    color: #000000;
   }

.modal__two .select_role {
 /* background-color: #fff;
 color: #657F95;
 border-radius: 20px;
 height: 50px; */
 background-color: #F9F9F9;
 color: #000000;
 border-radius: 4px;
 font-weight: 400;
 font-size: 14px;
 font-family: 'Sofia Pro';
 height: 45px;
 padding: 8px 12px;
 width: 94%;
}

.modal__wrapper_two .modal__two button {
 font-weight: bold;
 line-height: 15.81px;
 color: #FFFFFF;
 background-color: #D70900;
 /* width: 132px; */
height: 47px;
/* left: 618px; */
/* top: 552px; */
}