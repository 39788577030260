/* @import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@500;700&display=swap'); */
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@500&family=Source+Sans+Pro:wght@200;300&display=swap');

:root {
  --C_gray: #7995AC;
  --C_gray_lite: #CCD8E1;
  --C_danger: #FF0000;
  --C_danger_hover: #E20F00;
  --C_danger_disabled: #cd4b4b;
  --C_white: #FFFFFF;
  --C_black: #333;
  --C_primary_disabled:  #cd4b4b;
  --C_primary: #FF0000;
  --C_primary_hover: #E20F00;
  --F_mont: 'Montserrat', sans-serif;
}
.App {
  text-align: center;
  margin: 0;
  font-family: 'Montserrat', sans-serif;
}

.btn {
  @apply font-bold py-2 px-4 rounded;
  width: 70%;
  margin-left: 36px;
  margin-top: 50px;
}

.btn-blue {
  @apply bg-red-600 text-white;
}

.btn-blue:hover {
  @apply bg-red-900;
}

.sidebar-items {
  /* @apply bg-gray-200; */
  padding-top: 50px;
  width: 90%;
  height: 600px;
}

.sidebar {
  @apply bg-gray-200;
  width: 25%;
  height: 800px;
}

.flex-row {
  margin-top: -55%;
  /* margin: 0 auto; */
}

.bg-custom {
  background-image: url("./assets/images/background-img.png");
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
  padding-bottom: 30px;
  width: 100%;
}


.login-custom {
  margin: 0 auto;
}

.login-wrapper {
  margin: 0 auto;
  position: relative;
  /* background-color: #fff; */
  /* height: 582px;
  width: 440px; */
  left: 17%;
  /* border-radius: 30px; */
}

.login-wrapper2{
  width: 100%;
}

.login-img {
  position: absolute;
  margin: 0 auto;
  height: 67px;
  width: 144px;
  top: 50px;
  left: 125px;
  border-radius: 0px;
}


/* .login-form {
  margin: 0 auto;
  width: 75%;
} */

.login-form p{
  width: 75%;
  font-family: Circular Std;
}

.custom-formGroup {}

.custom-formGroup label {
  color: #99ACBB !important;


}

.custom-valid {
  margin: 0 auto;
  font-family: Circular std;
  line-height: 32px;
  letter-spacing: -0.035em;

}

.custom-valid2 {
  margin: 15px 0;
  width: 100%;
  font-family: "Sofia Pro";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  text-align: center;
  letter-spacing: -3.5%;
  color: #263238;
}

.rounded_input input {
  font-size: 22px;
}

.custom-formGroup input {
  margin-bottom: 0;
}

.input_wrapper {
  border-bottom: 1px solid #99ACBB;
}

input {
  font-size: 20px;
}

.input_wrapper input {
  color: #333 !important;
}

.login-form .tokenInput {
  font-weight: bolder;
  width: 55px;
  height: 55px;
  /* border-radius: 10px; */
}

.login-form .tokenInput::placeholder {
  /* color: #FF0000 !important; */
  /* font-size: 40px */
}

.token-form {
  font-family: Sofia Pro;
}
.error input {
  color: #FF0000 !important;
}

.login-custom-btn {
  height: 64px;
  /* width: 275px; */
  border-radius: 6px;
  background-color: #E20F00;
  color: #fff;
  ;

}


.Main__page {

}

.Main__page main {
  /* margin-top: 3em; */
  overflow-x: hidden;
  overflow-y: auto;
}

th {
  font-size: 14px !important;
}
.td, th {
  color: #444 !important;
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 12px;
  margin-top: 10px;
 }
 
 .custom-building {
  height: 269px;
width: 269px;
left: 190px;
top: -90px;
border-radius: 0px;

position: absolute;
 }

 
 /* .wraper-dash {
  position: relative;
 } */

 th, tr:nth-child(even){
  background-color: inherit !important;
 }
