body {
 overflow-x: hidden;
 font-family: 'Roboto';
 font-size: '3em';
}
.navbar {
 z-index: 5;
}
aside .active {
    /* border-left: 2px solid #FF0000; */
    background-color: #F5EBEB;
    /* transition: border-left .01s; */
}
.b-radius {
border-radius: 0 55px 0 55px !important;
padding: 15px !important; 
}
aside {
 height: 100%;
 width: 20%;
 /* background-color: #f5f5f5; */
 position: fixed;
 top: 0px;
 left: 0px;
 /* border-radius: 10px; */
 overflow-y: auto;
 padding: 0;
 border-right: 1px solid #D1D3D4;
 /* box-shadow: 3px 0 10px 2px rgba(0, 0, 0, 0.2); */
 transition: width, transform .4s;
 z-index: 4;
}
aside img:hover {
 /* background-color: #E20F00; */
 /* padding-right: 15px; */
 /* border-right: 2px solid #E20F00; */
}

.main-section {
 /* margin-left: 2em; */
 /* width: 100%; */
}

input {
 /* padding: 10px; */
}

aside div {
 cursor: pointer;
 padding: 5px 5px;
}
a {
 cursor: pointer;

}
.main-wrapper {
 /* display: flex; */
 /* justify-content: space-between; */
 display: -webkit-box;
 display: -webkit-flex;
 display: -ms-flexbox;
 display: flex;
 -webkit-box-pack: center;
 -webkit-justify-content: center;
 -ms-flex-pack: center;
 justify-content: center;
 /* padding-left: 20px; */
}


/* Create Page */
/* #create, #personal_shopper, :is(input[type="text"],input[type="url"], select,textarea, input[type="number"]) {
 border-width: 0px 0px 2px 0px;
} */

.dropdown-menu li input{
 color: #212529 !important;
}

#create .incoming_shipment_form .close i:hover{
 background-color: var(--bs-danger);
 color: var(--bs-white) !important;
}

/* Nav toggler icon */
.navbar-burger {
 position: absolute;
 right: 15px;
 top: 6px;
 padding: 20px;
}
/* .navbar-burger .navToggler {
 position: absolute;
 content: '';
 width: 80%;
 height: 4px;
 right: 0px;
 top: 18px;
 background-color: #6c757d;

 border: none;
 border-radius: 25px;
 transition: all .4s;
} */
.cursor-pointer {
 cursor: pointer !important;
}
.hide {
 transition: all .3s;
}

.card-min {
 min-height: 13rem;
}

@media screen and (min-width: 768.98px){

 aside:hover{
    /* visibility: visible;
     width: 120px; */
     /* transition: width 0s; */
     /* overflow-y: auto */
 }
 
 aside {
     /* width: 15%; */
     transition: all .2s;
     overflow-y: hidden;
     
 }
 
 
}



@media only screen and (max-width: 768px){
 aside {
     /* width: 50%; */
 }
}



.overlay{
position: fixed;
content: "";
top: 0;
bottom: 0;
right: 0;
left: 0;
background: rgba(0, 0, 0, 0.8);
/* width: 100%; */
height: 100%;
transform: translateX(0%);
z-index: 200;
transition: transform .5s ease;
margin: auto;
}


.user_menu_wrapper{
    /* position: fixed; */
    /* top: 100px;
    left: 70px */
}
.user_menu_wrapper  .btn {
    display: block;
    color: #535763;
    box-shadow: 0px 0px 0px 1px #fff;
    font-weight: 400;
    font-size: 12px;
    width: 100%;
    height: 34px;
    margin: 10px 6px;
}

.user_menu_wrapper  .btn p {
    margin-bottom: 10px !important;
}

.user_menu_wrapper  .btn:hover{
    background-color: #F5EBEB;
    border-left: 2px solid #FF0000;
    transition: border-left .01s;
    border-radius: 3px;
}

.sidebar-active:hover, .active{
    background-color: #F5EBEB;
    border-left: 2px solid #FF0000;
    transition: border-left .01s;
    border-radius: 3px;
} 
.text-active {
    color: #aa1717;
}


