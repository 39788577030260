div {
 /* margin-left: 100px */
}


.Users__screen {
 text-align: left;
}

.Users__screen .page__sub_title {
 font-style: normal;
 font-weight: 500;
 font-size: 15px;
 line-height: 18px;
 color: var(--C_gray_lite)
}

.Users__screen .page__title {
 font-size: 30px;
 font-family: var(--F_mont);
 color: var(--C_gray);
 font-style: normal;
 font-weight: 700;
 font-size: 30px;
 line-height: 37px;
}

.Users__screen .table__sort {
 /* border-radius: 50px; */
 padding: 8px 10px;
 font-size: 12px;
 line-height: 15px;
 font-family: var(--F_mont);
 background: #F7F9FB;
 border-radius: 9px;
}


.Users__screen .filter__sec label {
 font-style: italic;
 font-weight: 400;
 font-size: 11px;
 line-height: 11px;
 font-family: var(--F_mont);
 color: var(--C_gray);
}

.Users__screen .search__sec {
 width: 526px;
 height: 44px;
 background: #F7F9FB;
 border-radius: 20px;
}

.Users__screen .search__sec input {
 background-color: transparent;
 width: 526px;
 border-radius: 20px;
}

.Users__screen :where(label, .search__icon) {
 width: 20px;
 margin: 6px 10px 0 5px;
}

.Users__screen .search__sec input {
 outline: none;
 padding-right: 10px;
 font-weight: 500;
 font-size: 12px;
 color: rgba(84, 81, 81, 0.912);
 border-radius: 50px;
}

.Users__screen .search__sec input::placeholder {
 font-style: italic;
 font-weight: 500;
 font-size: 12px;
 line-height: 12px;
 color: var(--C_gray_lite);
}

.new_user__btn_div {
 /* background: linear-gradient(180deg, #FFB27A 0%, #FF0000 100%); */
 background: #F5EBEB;
 border: 1px solid #FF0000;
 border-radius: 5px;
 
}

.new_user__btn_div button {
 font-size: 13px;
 font-weight: bold;
 line-height: 15.85px;
}

tr{
 /* border-color: transparent !important; */
 /* border-width: 0 !important; */
 /* border: none !important; */
 border-top: 10px solid white !important;
}
thead th {
 font-style: normal;
 font-weight: 700;
 font-size: 12px;
 line-height: 12px;
 color: #7995AC;
 text-transform: capitalize;
}

.td {
 color: #7995AC;
 font-style: normal;
 font-weight: 300;
 font-size: 14px;
 line-height: 14px;
 margin-top: 10px;
 height: 60px;
}

.td button {
 height: 100%;
 width: 100%; 
}

tbody tr {
 height: 40px !important;
 background: #F7F9FB;
 /* border: 2px solid #D9D8E3; */
 border-radius: 60px !important;
}