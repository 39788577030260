.modal__wrapper .modal__one {
 min-width: 100%;
 height: 100%;
 background-color: #FFFFFF;
 display: block;
 border-radius: 5px;
 padding: 10px 20px;
}

.modal__wrapper2 .modal__one {
    min-width: 100%;
    height: 100%;
    background-color: #FFFFFF;
    display: block;
    border-radius: 5px;
    padding: 10px 20px;
}

.modal__wrapper3 .modal__one {
    min-width: 100%;
    height: 100%;
    background-color: #FFFFFF;
    display: block;
    border-radius: 5px;
    padding: 10px 20px;
}

.modal__wrapper {
 margin: 5vh 30vw;
 min-width: 600px;
 height: 800px;
 display: block;
 position: absolute;
 /* top: 20px; */
 z-index: 300;
}

.modal__wrapper2 {
    margin-top: -40vh;
    margin-right: 40vw;
    margin-left: 10vw;
    min-width: 600px;
    height: 800px;
    display: block;
    position: absolute;
    /* top: 0px; */
    z-index: 300;
}

.modal__wrapper3 {
    margin-top: -30vh;
    margin-left: -10vw;
    min-width: 400px;
    height: 210px;
    display: block;
    position: absolute;
    /* top: 0px; */
    z-index: 300;
}

.modal__one .select_role {
 /* background-color: #F9F9F9; */
 color: #000000;
 border-radius: 4px;
 font-weight: 400;
 font-size: 14px;
 font-family: 'Sofia Pro';
 height: 45px;
 padding: 8px 12px;
 width: 100%;
}

.modal__one .select_role::placeholder {
 /* color: #a39b9b; */
}

.modal__one h1 {
 font-weight: 500;
 line-height: 16px;
 letter-spacing: 0em;
 font-family: var(--F_mont);
 font-size: 16px;
 /* width: 90%; */
 color: #000000;
}


.modal__wrapper .modal__one button {
 font-weight: bold;
 line-height: 15.81px;
 color: #FFFFFF;
 background-color: #D70900;
 /* width: 132px; */
height: 47px;
left: 618px;
/* top: 552px; */
}

.modal__wrapper2 .modal__one button {
    font-weight: bold;
    line-height: 15.81px;
    color: #FFFFFF;
    background-color: #D70900;
    /* width: 132px; */
   height: 47px;
   left: 618px;
   /* top: 552px; */
   }

.modal__wrapper3 .modal__one button {
    font-weight: bold;
    line-height: 15.81px;
    color: #FFFFFF;
    background-color: #D70900;
    /* width: 132px; */
   height: 45px;
   left: 618px;
   /* top: 552px; */
}

.modal__one input {
 font-size: 16px;
 padding-left: 5px;
 
}

.modal__one .input__two {
 width: 90% !important;
}