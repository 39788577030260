.dashboard__page .search__sec {
 width: 526px;
 height: 44px;
 position: absolute;
 left: 480px;
 background: #F9F9F9;
 border-radius: 5px;
 overflow: hidden;
 border: 1px solid #D9D8E3;
}

.text-shadow {
  text-shadow: 0px 0px 10px rgb(205, 149, 149);

}

.div-1 {
    /* position: absolute; */
    /* left: 0px !important; */
}

.dashboard__page .notification {
    position: absolute;
    /* left: 1060px; */
    right: 0;

    top: 25px;
}



.dashboard__page .search__sec input {
 background-color: transparent;
 width: 526px;
 border-radius: 20px;
}

.dashboard__page :where(label, .search__icon) {
 width: 20px;
 margin: 6px 25px 0 5px;
 padding: 1px;
 color: #4b505d;
}

.dashboard__page .search__sec input {
 outline: none;
 padding-right: 10px;
 font-weight: 500;
 font-size: 12px;
 color: rgba(84, 81, 81, 0.912);
 border-radius: 50px;
}

.dashboard__page .search__sec input::placeholder {
 font-family: "Sofia Pro";
 font-size: 12px;
 line-height: 12px;
 color: #535763;
}

.userIcon img{
  /* width: 29px;
  height: 29px; */
  /* left: 900px;
  top: 36px;  */
  /* position: absolute; */
}

.userIcon p {
  /* width: 100px; */
  font-size:  14px;
  font-style: normal;
  font-family: "Sofia Pro";
  font-weight: 400;
  line-height: 12px;

}

.table {
  border: 1px solid #ddd;
}
.table th {
 
  border-top: 3px solid #ddd;
}

.table-one {
  border: 1px solid rgba(217, 216, 227, 0.3);

}

.custom-position{
  position: absolute;
  /* left: 0px; */
  right: 55px;
  top: 65px;
}